<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterList">
          <div class="row">
            <div class="col-lg-2">
              <SiteSelect
                v-if="vlm"
                v-model="filter.site"
                :options="sites"
                :label="$t('contentBlock.filter.site')"
                id="filter_site"
                class="mb-3"
              />

              <app-select
                v-else
                v-model="filter.site"
                :options="sites"
                :label="$t('contentBlock.filter.site')"
                id="filter_site"
              />
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.type"
                :options="contentBlockTypeValues"
                :label="$t('contentBlock.filter.type')"
                id="filter_type"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model.trim="filter.title"
                id="filter_title"
                :label="$t('contentBlock.filter.title')"
              >
              </app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model.trim="filter.identifier"
                id="filter_identifier"
                :label="$t('contentBlock.filter.identifier')"
              >
              </app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model.trim="filter.id"
                id="filter_id"
                :label="$t('contentBlock.filter.id')"
              >
              </app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success pull-right m-t-30"
                data-test="search"
                @click="filterList"
              >
                {{ $t('contentBlock.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import ContentBlockTypeMixin from '../mixins/valueObject/ContentBlockTypeMixin'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import ContentBlockFilter from '../../model/ContentBlockFilter'
import { MODULE_SYSTEM_CONTENT_BLOCK } from '@/model/ValueObject/UserPermissionModules'
import { mapGetters } from 'vuex'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'ContentBlockFilter',
  mixins: [ContentBlockTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ContentBlockFilter)
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_SYSTEM_CONTENT_BLOCK)
    }
  },
  components: {
    SiteSelect,
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterList () {
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/setFilter', this.filter)
      this.$store.dispatch('contentBlock/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(ContentBlockFilter)
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/resetFilter')
      this.$store.dispatch('contentBlock/fetch')
    }
  },
  created () {
    this.filter = this.$store.getters['contentBlock/filter']
  }
}
</script>
